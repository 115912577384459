import { Box, chakra, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Form, Formik } from 'formik';
import { PrimaryInput, RequestButton } from 'components';
import { getLowRates } from 'utils/yupSchemas';
import { submitForm } from 'utils/hubspot';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const Disclaimer = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    color: primaryTheme.colors.brand.white,
    whiteSpace: 'pre-line',
    opacity: '65%',
  },
});

const FieldContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'space-between !important',
    alignItems: 'space-between !important',
  },
});

const FieldRow = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column' },
    gap: {
      xl: '0.4em',
      '2xl': '0.9em',
    },
    width: '100%',
  },
});

const FormFields = ({
  setIsSubmitted,
  setFields,
  fields,
  path,
}: {
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setFields: React.Dispatch<React.SetStateAction<{ email: string; fullname: string; telephone: string }>>;
  fields: { email: string; fullname: string; telephone: string };
  path: string;
}) => {
  const formEl = useRef<HTMLFormElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const { inViewport, enterCount } = useInViewport(formRef);
  const history = useHistory();
  const id =
    path === '/sale1'
      ? '2132d129-11cd-4a44-b06f-22ebf5f6216f'
      : path === '/sale2'
      ? '917092d6-2dd8-43cc-ad09-2df924fa4ec5'
      : '18d8fa11-de63-4666-85ed-304306937f9f';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/21517867/${id}`;
  const [ipAddress, setIpAddress] = useState('');
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        const ipData = await response.json();
        setIpAddress(ipData.ip);
      } catch (err) {
        /* empty */
      }
    };
    fetchIPAddress();
  }, []);
  return (
    <Box opacity={enterCount >= 1 ? 1 : 0} ref={formRef}>
      <Formik
        validationSchema={getLowRates}
        initialValues={{
          email: fields?.email ?? '',
          fullname: fields.fullname ?? '',
          telephone: '',
        }}
        onSubmit={async (values) => {
          const hutk = Cookies.get('hubspotutk') || '';
          await submitForm(
            {
              fullname: values.fullname,
              email: values.email,
              phone: values.telephone,
              hutk,
              ipAddress,
            },
            url
          );
          history.push({
            pathname: path === '/sale1' ? '/schedule1' : path === '/sale2' ? '/schedule2' : '/get-app',
            state: {
              email: values.email,
              fullname: values.fullname,
              telephone: values.telephone,
              path,
            },
          });
          window.scrollTo(0, 0);
          setFields({ email: values.email, fullname: values.fullname, telephone: values.telephone });
          setIsSubmitted(true);
        }}
      >
        {() => (
          <Form ref={formEl}>
            <FieldContainer
              opacity={enterCount >= 1 ? 1 : 0}
              ref={formRef}
              padding={{ xl: path === '/grandopening' ? '75px 0 62px' : '62px 0' }}
            >
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <PrimaryInput name="fullname" label="Your Full Name *" type="text" placeholder="Full Name" required />
                <PrimaryInput name="email" label="Email *" type="email" placeholder="Email Address" required />
                <PrimaryInput
                  name="telephone"
                  label="Telephone *"
                  type="number"
                  placeholder="Telephone Number"
                  isMasked
                  required
                />
                <RequestButton
                  width="100%"
                  title={
                    path === '/grandopening'
                      ? `Secure your 2 weeks${isMobileView ? '\n' : ' '}of free classes`
                      : `LOCK IN YOUR LOWEST ${isMobileView ? '\n' : ' '}MEMBERSHIP RATE!`
                  }
                  type="submit"
                  marginTop={path === '/grandopening' ? '46px' : '5px'}
                  name="upviralsubmit"
                  value="Submit"
                />
                <input type="hidden" name="reflink" value="" />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <Disclaimer marginTop={path === '/grandopening' ? '33px' : '24px'}>
                  By entering, you agree to receive emails from Yoga Joint about news, updates, offers, and more. We respect your
                  privacy. Unsubscribe at any time.
                  {path !== '/grandopening' && '\n\nPre-sale rates are for new members only'}
                </Disclaimer>
              </FieldRow>
            </FieldContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FormFields;
